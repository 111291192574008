import Link from 'next/link';
import { WHITE_LABEL_ACCENT_COLOR } from '../../lib/white-label';
import styled from 'styled-components';
import { Transition } from '@tailwindui/react';
import { useMemo } from 'react';

interface ITidalNavbarItemBase {
  name: string;
  icon: any;
  link: string;
  shouldHideIfNotSeller?: boolean;
}

interface ITidalNavbarItemWithChildren extends ITidalNavbarItemBase {
  items?: ITidalNavbarItemBase[];
}

interface ITidalNavbarItemProps extends ITidalNavbarItemWithChildren {
  data: { me: { isStripeSellerAccountApproved: boolean } };
  onClick: (subSubIndex: number) => void;
  isActive: (link: string) => boolean;
  isParentActive: (link: string) => boolean;
}

const TidalNavbarItemWrapper = styled.div<{ isActive: boolean }>`
  outline: none;
  border: none;
  grid-gap: 18px;
  width: 100%;
  button {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 33px 1fr;
    grid-gap: 8px;
    @media only screen and (max-width: 800px) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    p {
      font-size: 18px;
      font-weight: 600;
      text-align: left;
      @media only screen and (max-width: 800px) {
        display: none;
      }
    }
    .icon-wrapper {
      height: 33px;
      width: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;

      @media only screen and (max-width: 320px) {
        height: 22px;
        width: 22px;
        background-color: transparent;
      }
    }
  }
`;

const NavbarLink = ({ link, name, isActive, icon: Icon, onClick }) => (
  <Link href={link}>
    <button
      aria-label={name}
      style={{
        textDecoration: 'none !important',
      }}
      onClick={onClick}
    >
      <div
        style={{
          backgroundColor: isActive
            ? `${WHITE_LABEL_ACCENT_COLOR}3d`
            : 'transparent',
        }}
        className="icon-wrapper"
      >
        <Icon color={isActive ? WHITE_LABEL_ACCENT_COLOR : '#000000'} />
      </div>
      <p style={{ color: isActive ? WHITE_LABEL_ACCENT_COLOR : '#000' }}>
        {name}
      </p>
    </button>
  </Link>
);

export const TidalNavbarItem = ({
  name,
  icon,
  link,
  items,
  onClick,
  isActive,
  isParentActive,
  data,
}: ITidalNavbarItemProps): JSX.Element => {
  const parentActive = useMemo(() => isParentActive(link), [link, isActive]);

  const hasActiveItem = items ? items?.some((i) => isActive(i.link)) : false;

  const showChildren = parentActive || items?.some((i) => isActive(i.link));

  return (
    <TidalNavbarItemWrapper isActive={parentActive} className="mb-2">
      <NavbarLink
        name={name}
        link={link}
        icon={icon}
        isActive={hasActiveItem ? false : parentActive}
        onClick={() => onClick(undefined)}
      />

      {items && (
        <>
          {items.map((i, idx) => {
            let showChild = showChildren;
            if (
              i.shouldHideIfNotSeller &&
              !data?.me?.isStripeSellerAccountApproved
            ) {
              showChild = false;
            }

            return (
              <Transition
                show={showChild}
                enter="transition-opacity duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-250"
                leaveFrom="opacity-75"
                leaveTo="opacity-0"
                key={`nav-item-${idx}`}
              >
                <div className="pl-0 md:pl-2 pt-2">
                  <NavbarLink
                    name={i.name}
                    link={i.link}
                    icon={i.icon}
                    isActive={isActive(i.link)}
                    onClick={() => onClick(idx)}
                  />
                </div>
              </Transition>
            );
          })}
        </>
      )}
    </TidalNavbarItemWrapper>
  );
};
