import * as React from "react"
import { SVGProps } from "react"

const Webhook = (props: {color : string}) => (
  <svg
    width={21}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.397 4.538a2.625 2.625 0 0 0-1.794 0L3.435 6.781l6.168 2.243c.58.21 1.215.21 1.794 0l6.167-2.243-6.167-2.243ZM9.005 2.894a4.375 4.375 0 0 1 2.99 0l6.168 2.243c1.535.558 1.535 2.73 0 3.289l-6.168 2.242a4.375 4.375 0 0 1-2.99 0L2.837 8.427c-1.536-.559-1.536-2.731 0-3.29l6.168-2.242Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.8 10.846a.875.875 0 0 1 1.118-.531l6.996 2.487c.379.135.793.135 1.172 0l6.996-2.487a.875.875 0 1 1 .586 1.648l-6.995 2.488a3.5 3.5 0 0 1-2.346 0l-6.995-2.488a.875.875 0 0 1-.531-1.117Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.8 14.565a.875.875 0 0 1 1.118-.532l6.996 2.487c.379.135.793.135 1.172 0l6.996-2.487a.875.875 0 1 1 .586 1.65l-6.995 2.486a3.5 3.5 0 0 1-2.346 0l-6.995-2.487a.875.875 0 0 1-.531-1.117Z"
      fill={props.color}
    />
  </svg>
)

export default Webhook
