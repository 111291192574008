import React from 'react';
import styled from 'styled-components';
import { WHITE_LABEL_BRAND, WHITE_LABEL_LOGO } from '../lib/white-label';

const ImageContainer = styled.img`
  max-width: ${process.env.WHITE_LABEL_ORG === 'copsupply' ? '10' : '5'}rem;
  margin: auto;
`;

const TidalLogoImage = () => (
  <ImageContainer src={WHITE_LABEL_LOGO} alt={`${WHITE_LABEL_BRAND} Logo`} />
);

export default TidalLogoImage;
