import React, { FC, useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import styled from 'styled-components';
import TidalLogoImage from '../TidalLogoImage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
`;

const Button = styled.button`
  height: 48px;
  font-weight: bold;
  border: none;
  color: whitesmoke;
  border-radius: 5px;
  margin-bottom: 10%;
  background: transparent linear-gradient(198deg, #14b1da 0%, #0d9de5 100%)
    no-repeat padding-box;
`;

const AppModal: FC<{ show: boolean; isIOS: boolean; isAndroid: boolean }> = ({
  show,
  isIOS,
  isAndroid,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storage = window.sessionStorage;
      const val = storage.getItem('hasSeenModal');
      if (val == null && show) {
        setOpen(show);
        storage.setItem('hasSeenModal', 'true');
      }
    }
  }, [window]);

  return (
    <>
      <BottomSheet onDismiss={() => setOpen(false)} open={open}>
        <Container>
          <TidalLogoImage />
          <p>
            Designed for you to be well informed on your rentals sales,
            purchases, and much more! This is just the first phase of the mobile
            app with plans for endless futures!
          </p>
          <Button
            onClick={() => {
              if (isIOS) {
                window.open(
                  'https://apps.apple.com/us/app/tidal-market/id1567811397',
                  '_blank',
                );
              } else if (isAndroid) {
                window.open(
                  'https://play.google.com/store/apps/details?id=com.tidal.tidalmarkets',
                  '_blank',
                );
              } else {
                alert('device not supported');
              }
            }}
          >
            Continue in App
          </Button>
        </Container>
      </BottomSheet>
    </>
  );
};

export default AppModal;
