import {
  WHITE_LABEL_ACCENT_COLOR,
  WHITE_LABEL_LIGHT_ACCENT_COLOR,
} from '../lib/white-label';

export const COLORS = {
  BLACK: '#111723',
  TEAL_BLUE: '#14B1DA',
  BLUE_GRAY: '#A0ADC2',
  GRAY: '#717884',
  ACCENT: WHITE_LABEL_ACCENT_COLOR,
  LIGHT_ACCENT: WHITE_LABEL_LIGHT_ACCENT_COLOR,
};

export const TRANSACTION_THEMES = {
  DEFAULT: {
    background: '#F6F9FC',
    border: 'rgba(0, 0, 0, 0.1)',
    accent: COLORS.TEAL_BLUE,
  },
  PURCHASES: {
    PENDING_PAYOUT: {
      background: '#3FD99A0D',
      border: '#3FD99A1C',
      accent: '#3ED698',
    },
    COMPLETED: {
      background: '#3FD99A0D',
      border: '#3FD99A1C',
      accent: '#3ED698',
    },
    REFUNDED: {
      background: '#E74C3C1C',
      border: '#E74C3C0D',
      accent: '#E74C3C',
    },
  },
  LISTINGS: {
    success: {
      background: '#1CC9CB0D',
      border: '#1CC9CB1C',
      accent: '#1CC9CB',
    },
    expired: {
      background: '#E74C3C0D',
      border: '#E74C3C1C',
      accent: '#E74C3C',
    },
  },
  SALES: {
    success: {
      background: '#FF5B910D',
      border: '#FF5B911C',
      accent: '#FF5B91',
    },
    cancelled: {
      background: '#E74C3C0D',
      border: '#E74C3C1C',
      accent: '#E74C3C',
    },
    refunded: {
      background: '#E67E220D',
      border: '#E67E221C',
      accent: '#E67E22',
    },
  },
};
