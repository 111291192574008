import * as React from 'react';
import { SVGProps } from 'react';

const Bots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={29}
    height={29}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.458 13.292c0-2.279 0-3.418.708-4.126.708-.707 1.847-.707 4.126-.707h2.416c2.279 0 3.418 0 4.126.707.708.708.708 1.847.708 4.126v2.417c0 2.278 0 3.417-.708 4.125-.708.708-1.847.708-4.126.708h-2.416c-2.279 0-3.418 0-4.126-.708-.708-.708-.708-1.847-.708-4.125v-2.417Z"
      stroke={props.color}
      strokeWidth={2}
    />
    <path
      d="M15.708 12.084h-2.416c-.668 0-1.209.54-1.209 1.208v2.417c0 .667.541 1.208 1.209 1.208h2.416c.668 0 1.209-.541 1.209-1.209v-2.416c0-.668-.541-1.208-1.209-1.208Z"
      fill={props.color}
    />
    <path
      d="M12.083 8.459V4.834M16.917 8.459V4.834M20.542 12.084h3.625M20.542 16.916h3.625M12.083 24.166v-3.625M16.917 24.166v-3.625M4.833 12.084h3.625M4.833 16.916h3.625"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

export default Bots;
