import { LOCAL_STORAGE } from '../constants';

const redirectUri = () => {
  // check localstorage redirect
  const uri = localStorage.getItem(LOCAL_STORAGE.REDIRECT_AFTER_AUTH);
  if (uri) {
    localStorage.removeItem(LOCAL_STORAGE.REDIRECT_AFTER_AUTH);
    return uri;
  }
  // check referrer redirect
  if (typeof document !== 'undefined') {
    if (
      document?.referrer?.includes('tidalmarket.com') ||
      document?.referrer?.includes('localhost')
    ) {
      console.log({
        referrer: document.referrer,
      });
      return document.referrer;
    }
  }

  // default to bots if no matches found above
  return '/bots';
};

export default redirectUri;
