import * as React from "react";
import { SVGProps } from "react";

const BotVault = (props: { color: string }) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.668 14.5a1 1 0 0 0-.865-.5H5.414l-2-2 1.999-2h5.39a1 1 0 0 0 .865-.5 5 5 0 1 1 0 4.999Z"
      stroke={props.color}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      stroke={props.color}
      strokeWidth={1.75}
    />
  </svg>
);

export default BotVault;
