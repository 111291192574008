import * as React from "react"
import { SVGProps } from "react"

const Faq = (props: {color : string}) => (
  <svg
    width={21}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 17.5a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm0 1.75a8.75 8.75 0 1 0 0-17.5 8.75 8.75 0 0 0 0 17.5Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 8.75c.483 0 .875.392.875.875v4.376a.875.875 0 1 1-1.75 0V9.625c0-.483.392-.875.875-.875Z"
      fill={props.color}
    />
    <path
      d="M10.5 7.875a.875.875 0 1 0 0-1.75.875.875 0 0 0 0 1.75Z"
      fill={props.color}
    />
  </svg>
)

export default Faq
