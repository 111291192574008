import axios from 'axios';
import Image from 'next/image';
import Link from 'next/link';
import React, { Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import useSWR, { useSWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';
import {
  WHITE_LABEL_BRAND,
  WHITE_LABEL_DISCORD_INVITE,
  WHITE_LABEL_GUILD_BANNER,
} from '../../lib/white-label';
import { TidalButton } from '../common/button';
import { Box } from '../Flex';

const TidalFooterDiscordWidgetWrapper = styled.div`
  border-radius: 14px;
  box-shadow: 0px 28px 95px rgba(27, 31, 38, 0.1);
  width: 100%;
  max-width: 350px;
`;

const TidalFooterDiscordWidgetHeaderWrapper = styled.div`
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  height: 70px;
  position: relative;
  & > span > img {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const TidalFooterDiscordWidgetBody = styled.div`
  position: relative;
  padding: 28px 25px;
  @media only screen and (max-width: 786px) {
    padding: 28px 16px;
  }
`;

const TidalFooterDiscordWidgetLOGO = styled.div`
  height: 70px;
  width: 70px;
  position: absolute;
  top: -35px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    border-radius: 100%;
    height: 65px;
    width: 65px;
  }
`;

const TidalFooterDiscordWidgetDiscordBranding = styled.div`
  position: absolute;
  right: 25px;
  top: 16px;
  @media only screen and (max-width: 400px) {
    position: inherit;
    margin-left: 25px;
  }
`;

const TidalFooterDiscordWidgetTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`;

const TidalFooterDiscordWidgetRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 400px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  grid-gap: 8px;
`;

const TidalFooterDiscordWidgetInfo = styled.div<{
  color: string;
}>`
  border-radius: 8px;
  background: #f0f2fa;
  height: 54px;
  display: flex;
  padding: 0 12px;
  flex-direction: column;
  justify-content: center;
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    @media only screen and (max-width: 786px) {
      font-size: 14px;
    }
  }
  .count {
    line-height: 12px;
    font-weight: 600;
    font-size: 14px;
    word-break: break-all;
    color: ${(props) => props.color};
    @media only screen and (max-width: 786px) {
      font-size: 12px;
    }
  }
`;

const TidalFooterDiscordWidget = (): JSX.Element => {
  const inviteUrl: string = WHITE_LABEL_DISCORD_INVITE;
  const inviteApiUrl =
    inviteUrl.replace(
      `https://discord.com/invite/`,
      `https://discord.com/api/invites/`,
    ) + `?with_counts=true`;

  const getInviteData = (url) => {
    return fetch(url)
      .then((_) => _.json())
      .catch((err) => {
        return null;
      });
  };

  const { data, isValidating } = useSWRImmutable(inviteApiUrl, getInviteData, {
    errorRetryInterval: 900000,
    fallbackData: null,
    suspense: true,
  });

  if (!data) return null;

  return (
    <TidalFooterDiscordWidgetWrapper>
      <TidalFooterDiscordWidgetHeaderWrapper>
        {!!data ? (
          <Image
            layout="fill"
            src={`${WHITE_LABEL_GUILD_BANNER}`}
            alt="Tidal banner"
          />
        ) : (
          <></>
        )}
      </TidalFooterDiscordWidgetHeaderWrapper>
      <TidalFooterDiscordWidgetBody>
        <TidalFooterDiscordWidgetLOGO>
          {!!data ? (
            <img
              alt=""
              src={`https://cdn.discordapp.com/icons/${data?.guild?.id}/${data?.guild?.icon}.webp`}
            />
          ) : (
            <></>
          )}
        </TidalFooterDiscordWidgetLOGO>
        <TidalFooterDiscordWidgetDiscordBranding>
          <svg
            width="88"
            height="24"
            viewBox="0 0 88 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_56_16725)">
              <path
                d="M18.5387 4.94824C17.1221 4.28542 15.6073 3.80371 14.0237 3.52942C13.8292 3.88105 13.602 4.354 13.4453 4.73023C11.7619 4.47706 10.0939 4.47706 8.4414 4.73023C8.28477 4.354 8.05239 3.88105 7.85616 3.52942C6.27081 3.80371 4.75431 4.28719 3.33765 4.95175C0.480234 9.26968 -0.294364 13.4804 0.0929356 17.6313C1.98812 19.0465 3.82479 19.9063 5.63046 20.4689C6.07629 19.8553 6.47391 19.203 6.81645 18.5156C6.16407 18.2677 5.53923 17.9618 4.94883 17.6067C5.10546 17.4906 5.25867 17.3693 5.40669 17.2445C9.00771 18.9288 12.9203 18.9288 16.4783 17.2445C16.6281 17.3693 16.7812 17.4906 16.9362 17.6067C16.344 17.9635 15.7175 18.2694 15.0651 18.5174C15.4076 19.203 15.8036 19.857 16.2511 20.4706C18.0585 19.908 19.8969 19.0483 21.7921 17.6313C22.2465 12.8193 21.0158 8.6473 18.5387 4.94824ZM7.30704 15.0785C6.22605 15.0785 5.33955 14.0693 5.33955 12.8404C5.33955 11.6115 6.20712 10.6006 7.30704 10.6006C8.40699 10.6006 9.29346 11.6097 9.27453 12.8404C9.27624 14.0693 8.40699 15.0785 7.30704 15.0785ZM14.578 15.0785C13.497 15.0785 12.6105 14.0693 12.6105 12.8404C12.6105 11.6115 13.478 10.6006 14.578 10.6006C15.6779 10.6006 16.5644 11.6097 16.5455 12.8404C16.5455 14.0693 15.6779 15.0785 14.578 15.0785Z"
                fill="#5865F2"
              />
              <path
                d="M29.4088 7.8512H34.1079C35.2407 7.8512 36.1977 8.03228 36.9828 8.3927C37.7658 8.75309 38.3529 9.25592 38.742 9.89939C39.1308 10.5429 39.327 11.2795 39.327 12.1093C39.327 12.9216 39.1239 13.6582 38.7177 14.3175C38.3115 14.9786 37.6935 15.5007 36.8622 15.8858C36.0309 16.2708 35.0013 16.4642 33.7707 16.4642H29.4088V7.8512ZM33.7224 14.2753C34.485 14.2753 35.0721 14.0802 35.4816 13.6916C35.8914 13.3013 36.0963 12.7704 36.0963 12.097C36.0963 11.4729 35.9136 10.9753 35.5488 10.6026C35.184 10.2299 34.6314 10.0418 33.8928 10.0418H32.4228V14.2753H33.7224Z"
                fill="#5865F2"
              />
              <path
                d="M46.3624 16.4537C45.7116 16.2814 45.1245 16.0317 44.6031 15.703V13.66C44.9973 13.9712 45.5256 14.2279 46.1883 14.4301C46.851 14.6305 47.4915 14.7307 48.111 14.7307C48.4002 14.7307 48.619 14.692 48.7669 14.6147C48.9151 14.5373 48.9892 14.4442 48.9892 14.3369C48.9892 14.2138 48.9496 14.1119 48.8686 14.0292C48.7876 13.9466 48.6309 13.878 48.3987 13.82L46.9528 13.4877C46.1248 13.2908 45.5377 13.0183 45.1885 12.6685C44.839 12.3203 44.6649 11.8632 44.6649 11.2971C44.6649 10.8207 44.8165 10.4075 45.1231 10.0541C45.4276 9.70076 45.8613 9.42824 46.4241 9.2366C46.9872 9.04322 47.6446 8.94653 48.4003 8.94653C49.075 8.94653 49.693 9.02036 50.2561 9.16805C50.8189 9.31574 51.2836 9.50384 51.6538 9.73415V11.6663C51.2749 11.436 50.8411 11.2549 50.3455 11.1178C49.8514 10.9824 49.3438 10.9156 48.8202 10.9156C48.0645 10.9156 47.6878 11.0475 47.6878 11.3094C47.6878 11.4325 47.7463 11.5239 47.8633 11.5855C47.9803 11.647 48.1954 11.7103 48.5071 11.7771L49.7119 11.9986C50.4988 12.1375 51.0856 12.3819 51.4711 12.73C51.8569 13.0781 52.0495 13.5932 52.0495 14.2754C52.0495 15.0226 51.7312 15.6151 51.0925 16.0546C50.4538 16.4941 49.5484 16.7139 48.3745 16.7139C47.6842 16.7121 47.0131 16.626 46.3624 16.4537Z"
                fill="#5865F2"
              />
              <path
                d="M54.8934 16.1952C54.2034 15.8506 53.6817 15.3829 53.334 14.7922C52.9863 14.2015 52.8108 13.5369 52.8108 12.7985C52.8108 12.0601 52.9914 11.3991 53.3529 10.8171C53.7144 10.2352 54.2445 9.77807 54.9435 9.44579C55.6422 9.11351 56.4771 8.94824 57.4497 8.94824C58.6545 8.94824 59.6547 9.20669 60.45 9.72356V11.9757C60.1695 11.7788 59.8422 11.6188 59.4687 11.4958C59.0952 11.3727 58.6959 11.3111 58.269 11.3111C57.522 11.3111 56.9385 11.45 56.5167 11.7296C56.0949 12.0091 55.8834 12.373 55.8834 12.8249C55.8834 13.2679 56.088 13.6301 56.4978 13.9149C56.9076 14.198 57.5013 14.3404 58.281 14.3404C58.6821 14.3404 59.0781 14.2806 59.4687 14.1628C59.8578 14.0433 60.1935 13.8973 60.474 13.725V15.9033C59.5911 16.4448 58.5669 16.7156 57.4014 16.7156C56.4204 16.7121 55.5837 16.5398 54.8934 16.1952Z"
                fill="#5865F2"
              />
              <path
                d="M63.4554 16.1953C62.76 15.8507 62.2299 15.3795 61.8648 14.78C61.5 14.1805 61.3158 13.5124 61.3158 12.774C61.3158 12.0356 61.4982 11.3763 61.8648 10.7979C62.2314 10.2195 62.7582 9.76586 63.4503 9.43709C64.1403 9.10832 64.965 8.94482 65.9202 8.94482C66.8757 8.94482 67.7001 9.10832 68.3904 9.43709C69.0807 9.76586 69.6075 10.216 69.969 10.7908C70.3305 11.3658 70.5111 12.025 70.5111 12.7723C70.5111 13.5106 70.3305 14.1787 69.969 14.7782C69.6075 15.3778 69.0789 15.8489 68.3835 16.1935C67.6881 16.5381 66.867 16.7104 65.9187 16.7104C64.9701 16.7104 64.149 16.5399 63.4554 16.1953ZM67.1166 14.0117C67.4094 13.7128 67.5573 13.3173 67.5573 12.825C67.5573 12.3327 67.4109 11.9407 67.1166 11.6506C66.8241 11.3587 66.4245 11.2128 65.9187 11.2128C65.4039 11.2128 65.001 11.3587 64.7067 11.6506C64.4142 11.9424 64.2678 12.3327 64.2678 12.825C64.2678 13.3173 64.4142 13.7128 64.7067 14.0117C64.9995 14.3106 65.4039 14.4618 65.9187 14.4618C66.4245 14.46 66.8241 14.3106 67.1166 14.0117Z"
                fill="#5865F2"
              />
              <path
                d="M77.751 9.40192V12.0602C77.4447 11.8545 77.0487 11.7525 76.5582 11.7525C75.9159 11.7525 75.4203 11.9512 75.0759 12.3485C74.73 12.7459 74.5578 13.3647 74.5578 14.2016V16.466H71.6058V9.26656H74.4978V11.5556C74.6577 10.7187 74.9178 10.1017 75.2757 9.70255C75.6321 9.30523 76.0932 9.10657 76.6545 9.10657C77.0796 9.10657 77.4447 9.20503 77.751 9.40192Z"
                fill="#5865F2"
              />
              <path
                d="M87.5592 7.6051V16.466H84.6069V14.8538C84.3573 15.4604 83.9787 15.9228 83.4693 16.2392C82.9596 16.5539 82.3296 16.7121 81.5826 16.7121C80.9148 16.7121 80.3331 16.5469 79.8354 16.2146C79.338 15.8823 78.9543 15.427 78.684 14.8485C78.4155 14.2701 78.2793 13.6161 78.2793 12.8847C78.2709 12.1305 78.4137 11.4536 78.708 10.8541C79.0008 10.2546 79.4154 9.78694 79.9491 9.45112C80.4828 9.11533 81.0921 8.94655 81.7755 8.94655C83.1819 8.94655 84.1251 9.57067 84.6069 10.8172V7.6051H87.5592ZM84.1665 13.9625C84.4677 13.6636 84.6174 13.275 84.6174 12.8004C84.6174 12.3415 84.471 11.967 84.1785 11.6804C83.8857 11.3939 83.4846 11.2497 82.9803 11.2497C82.4829 11.2497 82.0854 11.3956 81.7875 11.6874C81.4896 11.9793 81.3417 12.3573 81.3417 12.825C81.3417 13.2926 81.4896 13.6741 81.7875 13.9695C82.0854 14.2648 82.4778 14.4125 82.9683 14.4125C83.4657 14.4108 83.865 14.2613 84.1665 13.9625Z"
                fill="#5865F2"
              />
              <path
                d="M41.8146 10.033C42.6273 10.033 43.2864 9.42844 43.2864 8.68273C43.2864 7.93702 42.6273 7.33252 41.8146 7.33252C41.0016 7.33252 40.3428 7.93702 40.3428 8.68273C40.3428 9.42844 41.0016 10.033 41.8146 10.033Z"
                fill="#5865F2"
              />
              <path
                d="M40.3416 10.9631C41.2434 11.3605 42.3624 11.378 43.2849 10.9631V16.5047H40.3416V10.9631Z"
                fill="#5865F2"
              />
            </g>
            <defs>
              <clipPath id="clip0_56_16725">
                <rect width="87.6" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </TidalFooterDiscordWidgetDiscordBranding>
        <Box height={24} />
        <TidalFooterDiscordWidgetTitle>
          {data?.guild?.name}
        </TidalFooterDiscordWidgetTitle>
        <Box height={16} />
        <TidalFooterDiscordWidgetRow>
          <TidalFooterDiscordWidgetInfo color="#9DA3B7">
            {!isValidating && !!data ? (
              <p className="title">{data.approximate_member_count}</p>
            ) : (
              <svg
                className="animate-spin h-5 w-5 mr-3 ..."
                viewBox="0 0 24 24"
              ></svg>
            )}
            <p className="count">Total members</p>
          </TidalFooterDiscordWidgetInfo>
          <TidalFooterDiscordWidgetInfo color="#23E588">
            {!!data ? (
              <p className="title">{data.approximate_presence_count}</p>
            ) : (
              <svg
                className="animate-spin h-5 w-5 mr-3 ..."
                viewBox="0 0 24 24"
              ></svg>
            )}
            <p className="count">Members Online</p>
          </TidalFooterDiscordWidgetInfo>
        </TidalFooterDiscordWidgetRow>
        <Box height={16} />
        <Link href={inviteUrl} legacyBehavior>
          <a target="discord_invite">
            <TidalButton type="info" text="Join Discord" tint={false} />
          </a>
        </Link>
      </TidalFooterDiscordWidgetBody>
    </TidalFooterDiscordWidgetWrapper>
  );
};

export default function () {
  return (
    <Suspense fallback={<></>}>
      <TidalFooterDiscordWidget />
    </Suspense>
  );
}
