import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Blog from '../svgs/Blog';
import BotVault from '../svgs/BotVault';
import EbayViews from '../svgs/EbayViews';
import Faq from '../svgs/Faq';
import Profile from '../svgs/Profile';
import Servers from '../svgs/Servers';
import Settings from '../svgs/Settings';
import Webhook from '../svgs/Webhook';
import { LOGOUT } from '../../lib/graphql/mutations';

import { useCookies } from 'react-cookie';
import { COOKIE, LOCAL_STORAGE } from '../../constants';
import { initializeApollo } from '../../lib/apollo';
import Link from 'next/link';
import Bots from '../svgs/Bots';
import { WHITE_LABEL_BRAND, WHITE_LABEL_LOGO } from '../../lib/white-label';
import Image from 'next/image';
import Transcripts from '../svgs/Transcripts';
import Logout from '../svgs/Logout';
import { TidalNavbarItem } from './item';
import { CashIcon } from '@heroicons/react/solid';
import { CalendarClock } from 'lucide-react';
import { useQuery } from '@apollo/client';
import { ME_STRIPE_SELLER } from '../../lib/graphql/queries';

const TidalNavbarWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  width: 264px;
  height: 100%;
  position: fixed;
  top: 0;
  border-right: 1px solid ${(props) => props.theme.colors.borderColors[0]};
  transition: all 0.3s ease-in-out;
  @media only screen and (max-width: 800px) {
    width: 100px;
  }
  @media only screen and (max-width: 540px) {
    width: 80px;
  }
  @media only screen and (max-width: 320px) {
    width: 40px;
  }
`;

const TidalNavbarContainer = styled.div`
  padding: 34px 38px;
  padding-top: 0;
  overflow: auto;
  height: 100%;
  @media only screen and (max-width: 800px) {
    padding: 34px 12px;
  }
  @media only screen and (max-width: 320px) {
    padding: 34px 2px;
  }
`;

const TidalNavbarHeader = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColors[1]};
  padding-bottom: 24px;
  position: sticky;
  top: 0;
  .tidal-mobile-nav {
    display: none;
  }
`;

const TidalSubMenuContainer = styled.div`
  margin-top: 21px;
  margin-bottom: 32px;
`;

const TidalSubMenuHeader = styled.div`
  color: ${(props) => props.theme.colors.text[0]};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 14px;
  @media only screen and (max-width: 800px) {
    font-size: 12px;
    text-align: center;
  }
  @media only screen and (max-width: 540px) {
    font-size: 8px;
  }
  @media only screen and (max-width: 320px) {
    font-size: 4px;
  }
`;
const TidalNavbarLogoutButtonWrapper = styled.button`
  position: absolute;
  bottom: 0px;
  height: 60px;
  margin-left: 20px;
  width: calc(100% - 40px);
  left: 0;
  display: grid;
  grid-template-columns: 33px 1fr;
  grid-gap: 8px;
  padding: 0 24px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};
  border-top: 1px solid ${(props) => props.theme.colors.borderColors[1]};
  @media only screen and (max-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin-left: 0;
  }
  p {
    color: ${(props) => props.theme.colors.text[1]};
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    @media only screen and (max-width: 800px) {
      font-size: 12px;
      text-align: center;
      display: none;
    }
  }
`;

interface ITidalNavbarItem {
  name: string;
  Icon: any;
  isActive: boolean;
  link: string;
  onClick: () => void;
}

const defaultMenuItems = [
  {
    index: 0,
    name: 'RENTALS',
    isAuthed: false,
    shouldHideIfUnauthed: false,
    items: [
      {
        name: 'Bots',
        icon: Bots,
        link: '/bots',
        isActive: false,
        isAuthed: false,
        shouldHideIfUnauthed: false,
        items: [
          {
            name: 'Guides',
            icon: Transcripts,
            link: '/bots/guides',
            isActive: false,
            isAuthed: true,
            shouldHideIfUnauthed: true,
          },
        ],
      },
      {
        name: 'My Profile',
        icon: Profile,
        link: '/dashboard',
        isActive: false,
        isAuthed: true,
        shouldHideIfUnauthed: false,
        items: [
          {
            name: 'Purchases',
            icon: BotVault,
            link: '/dashboard/transactions/purchases',
            isActive: false,
            isAuthed: true,
            shouldHideIfUnauthed: false,
          },
          {
            name: 'Sales',
            icon: ({ color }) => (
              <CashIcon width={24} height={24} color={color} />
            ),
            link: '/dashboard/transactions/sales',
            isActive: false,
            isAuthed: true,
            shouldHideIfUnauthed: false,
            shouldHideIfNotSeller: true,
          },
          {
            name: 'Listings',
            icon: ({ color }) => (
              <CalendarClock
                width={24}
                height={24}
                color={color}
                className="p-0.5"
              />
            ),
            link: '/dashboard/transactions/listings',
            isActive: false,
            isAuthed: true,
            shouldHideIfUnauthed: false,
            shouldHideIfNotSeller: true,
          },
          {
            name: 'Settings',
            icon: Settings,
            link: '/dashboard/settings',
            isActive: false,
            isAuthed: true,
            shouldHideIfUnauthed: true,
          },
        ],
      },
      {
        index: 6,
        name: 'Blog',
        icon: Blog,
        link: '/blog',
        isActive: false,
        isAuthed: false,
        shouldHideIfUnauthed: false,
      },
      {
        index: 7,
        name: 'FAQ',
        icon: Faq,
        link: '/faq',
        isActive: false,
        isAuthed: false,
        shouldHideIfUnauthed: false,
      },
    ],
  },
  // {
  //   index: 1,
  //   name: 'MARKETPLACE',
  //   isAuthed: true,
  //   shouldHideIfUnauthed: false,

  //   items: [
  //     {
  //       index: 0,
  //       name: 'Shop',
  //       icon: Cart,
  //       link: '/shop',
  //       isActive: false,
  //       isAuthed: false,
  //       shouldHideIfUnauthed: false,
  //     },
  //     {
  //       index: 1,
  //       name: 'Orders',
  //       icon: Orders,
  //       link: '/shop/orders',
  //       isActive: false,
  //       isAuthed: true,
  //       shouldHideIfUnauthed: true,
  //     },
  //     {
  //       index: 2,
  //       name: 'Merchant',
  //       icon: Profile,
  //       link: '/shop/merchant/items',
  //       isActive: false,
  //       isAuthed: true,
  //       shouldHideIfUnauthed: true,
  //     },
  //   ],
  // },
];

if (WHITE_LABEL_BRAND === 'Tidal') {
  defaultMenuItems.push({
    index: 2,
    name: 'DASHBOARD',
    isAuthed: false,
    shouldHideIfUnauthed: false,

    items: [
      {
        index: 0,
        name: 'Dashboard',
        icon: Profile,
        link: '/tidal-dashboard/profile',
        isActive: false,
        isAuthed: true,
        shouldHideIfUnauthed: false,
      },
      {
        index: 1,
        name: 'Bot Vault',
        icon: BotVault,
        link: '/tidal-dashboard/bot-vault',
        isActive: false,
        isAuthed: true,
        shouldHideIfUnauthed: false,
      },
      {
        index: 2,
        name: 'Servers',
        icon: Servers,
        link: '/tidal-dashboard/servers',
        isActive: false,
        isAuthed: true,
        shouldHideIfUnauthed: false,
      },
      {
        index: 3,
        name: 'Webhook',
        icon: Webhook,
        link: '/tidal-dashboard/webhooks',
        isActive: false,
        isAuthed: true,
        shouldHideIfUnauthed: false,
      },
      {
        index: 4,
        name: 'Ebay Views',
        icon: EbayViews,
        link: '/tidal-dashboard/ebay-views',
        isActive: false,
        isAuthed: true,
        shouldHideIfUnauthed: false,
      },
    ],
  });
}

interface TidalNavbarProps {
  isLoggedIn: boolean;
  onLoggedOut: () => void;
  meData: any;
}

const TidalNavbar = ({
  isLoggedIn,
  onLoggedOut,
  meData,
}: TidalNavbarProps): JSX.Element => {
  const router = useRouter();
  const [cookies] = useCookies([COOKIE.ACCESS_TOKEN, COOKIE.REFRESH_TOKEN]);
  const { data } = useQuery(ME_STRIPE_SELLER);

  useEffect(() => {
    try {
      const link = router.asPath;

      if (link) {
        const findItems = (linkPath) => {
          const currentMenuItemIndex = menuItems.findIndex((item) =>
            item.items.find((subItem) => subItem.link === linkPath),
          );

          if (currentMenuItemIndex !== -1) {
            const currentLinkIndex = menuItems[
              currentMenuItemIndex
            ].items.findIndex((item) => linkPath === item.link);
            if (currentLinkIndex !== -1) {
              return { currentMenuItemIndex, currentLinkIndex };
            }
          }
          return {};
        };

        let foundItem = findItems(link);

        const notFound = (foundItem) =>
          !foundItem.currentMenuItemIndex || !foundItem.currentLinkIndex;

        if (notFound(foundItem)) {
          const linkParts = link.split('/');

          let newLink = link;
          linkParts.reverse().forEach((part) => {
            if (notFound(foundItem)) {
              newLink = newLink.replace(`/${part}`, '');
              foundItem = findItems(newLink);
            }
          });
        }

        if (foundItem.currentMenuItemIndex && foundItem.currentLinkIndex) {
          const { currentMenuItemIndex, currentLinkIndex } = foundItem;
          handleUpdateNavbar(
            currentMenuItemIndex,
            currentLinkIndex,
            undefined,
            false,
          );
        }
      }
    } catch (e) {}
  }, [router.asPath]);

  const [menuItems, setMenuItems] = useState(defaultMenuItems);

  useEffect(() => {}, [meData]);

  const isItemActive = useCallback(
    (link) => {
      return router.asPath.includes(link);
    },
    [router.asPath],
  );

  const isParentActive = useCallback(
    (link) => {
      if ((link.match(/\//g) || []).length > 1) {
        return;
      }
      return router.asPath.includes(link);
    },
    [router.asPath],
  );

  const handleUpdateNavbar = (
    index,
    subIndex,
    subSubIndex,
    willNavigate,
  ): void => {
    const newMenuItems = [...menuItems];
    newMenuItems.forEach((item) => {
      item.items.forEach((subItem) => {
        subItem.isActive = false;
      });
    });
    let foundItem = newMenuItems[index].items[subIndex];

    if (foundItem.items && typeof subSubIndex !== 'undefined') {
      const subFoundItem = foundItem.items?.[subSubIndex];
      setMenuItems(newMenuItems);
      if (willNavigate) navigatePage(subFoundItem.link, subFoundItem.isAuthed);
    } else {
      setMenuItems(newMenuItems);
      if (willNavigate) navigatePage(foundItem.link, foundItem.isAuthed);
    }
  };

  const navigatePage = (link, isAuthed) => {
    if (isAuthed) {
      if (!cookies[COOKIE.ACCESS_TOKEN]) {
        localStorage.setItem(LOCAL_STORAGE.REDIRECT_AFTER_AUTH, link);
        return router.push('/login');
      }
    }
    router.push(link);
  };

  const handleClearActiveLinks = () => {
    const newMenuItems = [...menuItems];
    newMenuItems.forEach((item) => {
      item.items.forEach((subItem) => {
        subItem.isActive = false;
      });
    });
    setMenuItems(newMenuItems);
  };

  const onLogout = () => {
    if (confirm('Are you sure you want to log out?')) {
      initializeApollo()
        .mutate({
          mutation: LOGOUT,
          variables: {
            refreshToken: cookies[COOKIE.REFRESH_TOKEN],
          },
        })
        .then(onLoggedOut)
        .catch((err) => {});
    }
  };

  return (
    <TidalNavbarWrapper>
      <TidalNavbarContainer>
        <TidalNavbarHeader className="pt-0 md:pt-10">
          <div className="tidal-desktop-nav">
            <Image
              height={30}
              width={100}
              onClick={() => {
                router.push('/');
                handleClearActiveLinks();
              }}
              src={WHITE_LABEL_LOGO}
              alt={`${WHITE_LABEL_BRAND} Logo`}
            />
            {/* <Logo
              onClick={() => {
                router.push('/');
                handleClearActiveLinks();
              }}
            /> */}
          </div>
          <div className="tidal-mobile-nav">
            <svg
              width="57"
              height="60"
              viewBox="0 0 57 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M46.2525 17.267L41.2199 1.24121H12.5593L6.82422 7.32938L11.8567 23.3559H17.262L21.0766 35.5036H38.8978L44.6329 29.4154L42.1767 21.5939L46.2525 17.267Z"
                fill="url(#paint0_linear_592_33)"
              />
              <g opacity="0.69">
                <path
                  d="M28.5139 4.943L25.9173 7.42472L24.6128 7.08422L28.0429 3.44336L28.5139 4.943Z"
                  fill="url(#paint1_linear_592_33)"
                />
                <path
                  d="M42.0758 16.6094L38.6456 20.251L35.5024 20.2423L36.8998 16.6094H42.0758Z"
                  fill="url(#paint2_linear_592_33)"
                />
                <path
                  d="M40.5328 28.1797L37.1027 31.8205H32.0811L35.5112 28.1797H40.5328Z"
                  fill="url(#paint3_linear_592_33)"
                />
                <path
                  d="M18.5812 16.6093L15.1511 20.2509L11.4878 8.58422L14.9179 4.94336L18.5812 16.6093Z"
                  fill="url(#paint4_linear_592_33)"
                />
                <path
                  d="M23.729 16.6094L20.2988 20.251H15.1509L18.581 16.6094H23.729Z"
                  fill="url(#paint5_linear_592_33)"
                />
                <path
                  d="M36.0073 29.759L32.5772 33.4006L25.5103 30.1855L28.9404 26.5439L36.0073 29.759Z"
                  fill="url(#paint6_linear_592_33)"
                />
                <path
                  d="M29.5414 23.4707L26.1112 27.1123L23.0601 29.0442L26.4902 25.4026L29.5414 23.4707Z"
                  fill="url(#paint7_linear_592_33)"
                />
                <path
                  d="M26.4901 25.4029L23.0599 29.0445L20.2988 20.251L23.729 16.6094L26.4901 25.4029Z"
                  fill="url(#paint8_linear_592_33)"
                />
              </g>
              <path
                d="M39.0453 6.95703L42.0762 16.6094H36.9002L40.533 28.18H35.5114L36.0073 29.7592L28.9404 26.5441L33.9886 23.3291L34.4556 24.8172H35.8194L32.1865 13.2474H37.3626L35.9941 8.88893L39.0453 6.95703Z"
                fill="white"
              />
              <path
                d="M28.0428 3.44336L35.1089 6.65841L30.0616 9.87275L29.5695 8.30573H19.6316L21.1833 13.2469H26.3312L29.5415 23.4706L26.4903 25.4025L23.7292 16.609H18.5813L14.918 4.943H28.5138L28.0428 3.44336Z"
                fill="white"
              />
              <path
                d="M1.77148 46.6334H3.52827V44.3359H7.24068V46.6334H10.1609V49.5911H7.24068V54.0277C7.24068 54.756 7.83485 55.2793 8.65125 55.2793H10.1609V58.4643H8.03291C6.77126 58.4643 5.68195 58.1001 4.81564 57.3947C3.94934 56.6664 3.52905 55.7567 3.52905 54.6872V49.5911H1.77148V46.6334Z"
                fill="white"
              />
              <path
                d="M16.4229 46.6338H12.7104V58.4639H16.4229V46.6338Z"
                fill="white"
              />
              <path
                d="M20.8278 48.1125C21.9912 46.9297 23.4509 46.3376 25.1835 46.3376C26.4951 46.3376 27.6834 46.7017 28.747 47.43V41.4014H32.4345V58.4645H28.747V57.6451C27.7076 58.3956 26.52 58.7598 25.1835 58.7598C23.4509 58.7598 21.9912 58.1684 20.8278 56.9856C19.6644 55.7799 19.0952 54.301 19.0952 52.549C19.0952 50.7741 19.6644 49.2953 20.8278 48.1125ZM22.9558 52.5261C22.9558 54.1641 24.2174 55.3928 25.826 55.3928C27.4097 55.3928 28.6971 54.1641 28.6971 52.5261C28.6971 51.7297 28.425 51.0702 27.8557 50.5239C27.2865 49.9777 26.6183 49.7046 25.826 49.7046C24.2182 49.7053 22.9558 50.9562 22.9558 52.5261Z"
                fill="white"
              />
              <path
                d="M36.84 48.1118C38.0034 46.929 39.4631 46.3369 41.1957 46.3369C42.5073 46.3369 43.6949 46.7011 44.7592 47.4294V46.633H48.4467V58.4631H44.7592V57.6437C43.7198 58.3943 42.5322 58.7584 41.1957 58.7584C39.4631 58.7584 38.0034 58.167 36.84 56.9842C35.6766 55.7785 35.1074 54.2996 35.1074 52.5477C35.1074 50.7735 35.6766 49.2946 36.84 48.1118ZM38.968 52.5254C38.968 54.1634 40.2296 55.3921 41.8383 55.3921C43.4219 55.3921 44.7093 54.1634 44.7093 52.5254C44.7093 51.729 44.4372 51.0695 43.868 50.5233C43.2987 49.9771 42.6305 49.7039 41.8383 49.7039C40.2304 49.7047 38.968 50.9555 38.968 52.5254Z"
                fill="white"
              />
              <path
                d="M51.5161 58.4635V41.4004H55.2285V58.4635H51.5161Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_592_33"
                  x1="6.82407"
                  y1="18.3725"
                  x2="46.2525"
                  y2="18.3725"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#0078FB" />
                  <stop offset="1" stopColor="#20D3C6" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_592_33"
                  x1="24.6125"
                  y1="5.43385"
                  x2="28.5136"
                  y2="5.43385"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2F2F2" stopOpacity="0" />
                  <stop offset="1" stopColor="#F2F2F2" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_592_33"
                  x1="35.8953"
                  y1="20.5456"
                  x2="40.1046"
                  y2="15.0889"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2F2F2" stopOpacity="0" />
                  <stop offset="1" stopColor="#F2F2F2" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_592_33"
                  x1="34.5544"
                  y1="33.0299"
                  x2="37.6211"
                  y2="26.7562"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2F2F2" stopOpacity="0" />
                  <stop offset="1" stopColor="#F2F2F2" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_592_33"
                  x1="12.8164"
                  y1="13.1434"
                  x2="17.1988"
                  y2="11.8661"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2F2F2" stopOpacity="0" />
                  <stop offset="1" stopColor="#F2F2F2" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear_592_33"
                  x1="15.8157"
                  y1="21.0334"
                  x2="22.5932"
                  y2="15.2735"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2F2F2" stopOpacity="0" />
                  <stop offset="1" stopColor="#F2F2F2" />
                </linearGradient>
                <linearGradient
                  id="paint6_linear_592_33"
                  x1="28.657"
                  y1="32.4456"
                  x2="32.4313"
                  y2="27.1908"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2F2F2" stopOpacity="0" />
                  <stop offset="1" stopColor="#F2F2F2" />
                </linearGradient>
                <linearGradient
                  id="paint7_linear_592_33"
                  x1="23.0598"
                  y1="26.2574"
                  x2="29.5412"
                  y2="26.2574"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2F2F2" stopOpacity="0" />
                  <stop offset="1" stopColor="#F2F2F2" />
                </linearGradient>
                <linearGradient
                  id="paint8_linear_592_33"
                  x1="21.104"
                  y1="23.511"
                  x2="25.6056"
                  y2="21.92"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2F2F2" stopOpacity="0" />
                  <stop offset="1" stopColor="#F2F2F2" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </TidalNavbarHeader>

        {menuItems.map((menuItem, index) => {
          return (
            <TidalSubMenuContainer key={index}>
              {!isLoggedIn && menuItem.shouldHideIfUnauthed ? null : (
                <TidalSubMenuHeader>{menuItem.name}</TidalSubMenuHeader>
              )}
              {menuItem.items.map((item, subIndex) => {
                if (!isLoggedIn && item.shouldHideIfUnauthed) return null;

                if (item.name === 'Home') return null;
                if (
                  index == 1 &&
                  subIndex == 2 &&
                  !['merchant'].includes(meData?.role)
                )
                  return null;
                return (
                  <div key={subIndex}>
                    <TidalNavbarItem
                      data={data}
                      name={item.name}
                      isActive={isItemActive}
                      isParentActive={isParentActive}
                      icon={item.icon}
                      link={item.link}
                      items={item.items}
                      onClick={(subSubIndex) => {
                        handleUpdateNavbar(index, subIndex, subSubIndex, true);
                      }}
                    />
                  </div>
                );
              })}
            </TidalSubMenuContainer>
          );
        })}
        {isLoggedIn ? (
          <TidalNavbarLogoutButtonWrapper
            onClick={onLogout}
            aria-label="Logout"
          >
            <Logout />
            <p>Logout</p>
          </TidalNavbarLogoutButtonWrapper>
        ) : (
          <Link href="/login">
            <TidalNavbarLogoutButtonWrapper
              onClick={() => {}}
              aria-label="Login"
            >
              <Logout />

              <p>Login</p>
            </TidalNavbarLogoutButtonWrapper>
          </Link>
        )}
      </TidalNavbarContainer>
    </TidalNavbarWrapper>
  );
};

export default TidalNavbar;
