import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'universal-cookie';
import { configureRefreshFetch } from 'refresh-fetch';
import { COOKIE } from '../constants';
const cookies = new Cookies();

axios.defaults.baseURL = `${process.env.BACKEND_URI}`;

function handleErrors(response) {
  // console.log(response);
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

const refreshToken = () => {
  return new Promise((resolve, reject) => {
    const token = `${cookies.get(COOKIE.REFRESH_TOKEN)}`;
    if (!token) reject('No refresh token');
    fetch(`${process.env.BACKEND_URI}/auth/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ token }),
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then(resolve)
      .catch(reject);
  });
};

export const JSONClient = axios.create({
  baseURL: `${process.env.BACKEND_URI}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

JSONClient.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${cookies.get(
    COOKIE.ACCESS_TOKEN,
  )}`;
  return config;
});

let refreshPromise;

JSONClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const config = error.config;
    // Check status code and retry flag
    console.log(error);
    if (
      error.response &&
      error.response.data.message == 'EXPIRED_ACCESS_TOKEN' &&
      !refreshPromise
    ) {
      try {
        refreshPromise = refreshToken()
          .then((v: any) => {
            cookies.set(COOKIE.ACCESS_TOKEN, v.token, { path: '/' });
          })
          .catch((c) => {
            cookies.remove(COOKIE.ACCESS_TOKEN, { path: '/' });
            cookies.remove(COOKIE.REFRESH_TOKEN, { path: '/' });
            return Promise.reject(c);
          });
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (
      error.response &&
      error.response.status === 401 &&
      !refreshPromise
    ) {
      cookies.remove(COOKIE.ACCESS_TOKEN, { path: '/' });
      cookies.remove(COOKIE.REFRESH_TOKEN, { path: '/' });
      return window?.location.assign('/login');
    }

    if (refreshPromise) {
      try {
        await refreshPromise;
        refreshPromise = null;
        return JSONClient(config);
      } catch (error) {
        return window?.location.assign('/login');
      }
    }

    return Promise.reject(error);
  },
);

export const axiosClient = axios.create({
  baseURL: `${process.env.BACKEND_URI}/dashboard`,
});

function ResponseError(status: number, response: Response, body: any) {
  this.name = 'ResponseError';
  this.status = status;
  this.response = response;
  this.body = body;
}

const fetchWithToken = (url, options: any = {}) => {
  const token = cookies.get(COOKIE.ACCESS_TOKEN);

  let optionsWithToken = options;
  if (token != null) {
    optionsWithToken.headers = {
      ...optionsWithToken.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return fetch(url, optionsWithToken).then((response) => {
    if (response.ok) {
      return response;
    } else {
      return response.json().then((body) => {
        throw new ResponseError(response.status, response, body);
      });
    }
  });
};

const shouldRefreshToken = (error) =>
  error.status === 401 && error.body.message === 'EXPIRED_ACCESS_TOKEN';

export const fetchClient = configureRefreshFetch({
  fetch: fetchWithToken,
  shouldRefreshToken,
  refreshToken,
});

export class FetcherError extends Error {
  public status: number;
  public response: AxiosResponse<any>;

  constructor(message: string, response: AxiosResponse<any>) {
    super(message);
    this.status = response.status;
    this.response = response;
  }
}

export class FetcherClientError extends Error {
  public request?: any;

  constructor(message: string, request?: any) {
    super(message);
    this.request = request;
  }
}

export const fetcher = (url, config?: AxiosRequestConfig) => {
  const BACKEND_URI = `${
    url.includes('market/')
      ? process.env.SHOP_BACKEND_URI
      : process.env.BACKEND_URI
  }`;

  return JSONClient(`${BACKEND_URI}${url}`, {
    ...(config || {}),
    validateStatus: (status) => status < 400,
  })
    .then((response) => {
      if (response.status) return response.data;
    })
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          throw new FetcherError(
            error?.response?.data?.message || '',
            error.response,
          );
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          throw new FetcherClientError(error.message, error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          throw new FetcherClientError(error.message);
        }
      } else {
        throw error;
      }
    });
};

export async function sendRequest(url, { arg: payload }) {
  return JSONClient.post(url, payload).then((res) => res.data);
}
