import * as React from "react"
import { SVGProps } from "react"

const Blog = (props: {color : string}) => (
  <svg
    width={21}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.125 9.625c0-.483.392-.875.875-.875h7a.875.875 0 1 1 0 1.75H7a.875.875 0 0 1-.875-.875ZM6.125 13.125c0-.483.392-.875.875-.875h3.5a.875.875 0 1 1 0 1.75H7a.875.875 0 0 1-.875-.875Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.375 16.625a2.625 2.625 0 0 1-2.625 2.625H5.25a2.625 2.625 0 0 1-2.625-2.625V4.375A2.625 2.625 0 0 1 5.25 1.75H14l.01.01c.614.051 1.194.32 1.634.759l1.962 1.962c.44.44.707 1.02.76 1.635l.009.009v10.5Zm-2.625.875H5.25a.875.875 0 0 1-.875-.875V4.375c0-.483.392-.875.875-.875h7.875v1.75c0 .966.784 1.75 1.75 1.75h1.75v9.625a.875.875 0 0 1-.875.875Z"
      fill={props.color}
    />
  </svg>
)

export default Blog
