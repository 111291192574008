import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getApps, initializeApp } from 'firebase/app';
import localforage from 'localforage';
import { toast } from 'react-toastify';
import { WHITE_LABEL_ACCENT_COLOR, WHITE_LABEL_MARKET } from './white-label';
import { isDesktop } from 'react-device-detect';

const IGNORE_NOTIFICATION_SESSION_KEY = 'IGNORE_NOTIFICATION_PROMPT';

const Toast = ({
  title,
  body,
  onClick,
}: {
  title: string;
  body: string;
  onClick?: any;
}) => (
  <div
    className="flex items-center justify-between w-full p-2 text-gray-500 bg-transparent dark:text-gray-400"
    role="alert"
  >
    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-800 dark:text-blue-200">
      <svg
        aria-hidden="true"
        className="w-5 h-5"
        fill={`${WHITE_LABEL_ACCENT_COLOR}`}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <span className="sr-only">Fire icon</span>
    </div>
    <div className="ml-3 max-w-tiny mr-auto">
      <div className="text-sm font-normal">{title}</div>
      <div className="text-xs font-normal">{body}</div>
    </div>

    {onClick && (
      <button
        type="button"
        className="ml-auto mr-2 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-2 hover:bg-gray-100 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
        data-dismiss-target="#toast-default"
        aria-label="Accept"
        onClick={onClick}
      >
        <span className="sr-only">Accept</span>
        Accept
      </button>
    )}
  </div>
);

const ToastCloseButton = ({ closeToast }) => (
  <button
    className="Toastify__close-button Toastify__close-button--default"
    type="button"
    aria-label="close"
    onClick={() => {
      window?.localStorage?.setItem(IGNORE_NOTIFICATION_SESSION_KEY, 'true');
    }}
  >
    <svg aria-hidden="true" viewBox="0 0 14 16">
      <path
        fill-rule="evenodd"
        d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"
      ></path>
    </svg>
  </button>
);

const firebaseCloudMessaging = {
  //checking whether token is available in indexed DB
  tokenInlocalforage: async () => {
    return localforage.getItem('fcm_token');
  },
  //initializing firebase app
  init: async function () {
    const updateToken = async (app, initStatus?: string) => {
      try {
        const messaging = getMessaging(app);
        const tokenInLocalForage = await this.tokenInlocalforage();
        //requesting notification permission from browser
        const status = initStatus || (await Notification.requestPermission());
        if (status && status === 'granted') {
          //getting token from FCM
          const fcm_token = await getToken(messaging, {
            vapidKey:
              'BOS9NZg0hSce7q2UrnQx24fNUhkAO5bG3WVt1CyiVZSBDnVBAEKEIcL3IaU7IyMeEtyrs4fKWnKv05HIhC_Hyrs',
          });

          if (fcm_token !== tokenInLocalForage) {
            //setting FCM token in indexed db using localforage
            await localforage.setItem('fcm_token', fcm_token);
          }

          onMessage(messaging, (message) => {
            const url = message?.data?.link;

            navigator?.serviceWorker
              ?.getRegistration('/firebase-messaging-sw')
              ?.then((registration) => {
                const notificationTitle = message?.data?.title;
                const notificationOptions = {
                  body: message?.data?.body,
                  vibrate: [300, 100, 300],
                  // icon: '../icon/tidal-logo.png',
                  data: url ? { url } : undefined,
                  actions: url
                    ? [{ action: 'open_url', title: 'Open' }]
                    : undefined,
                };
                return registration.showNotification(
                  notificationTitle,
                  notificationOptions,
                );
              });
          });
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const apps = getApps();
    if (!apps.length) {
      const app = initializeApp({
        apiKey: 'AIzaSyCb-9YNf0iwnfe3UfY1I9zFue2yxg9KM50',
        authDomain: 'tidal-market-app-staging.firebaseapp.com',
        projectId: 'tidal-market-app-staging',
        storageBucket: 'tidal-market-app-staging.appspot.com',
        messagingSenderId: '739737537788',
        appId: '1:739737537788:web:0b7936a889b75957961b79',
        measurementId: 'G-VWRWLRY3M6',
      });

      if (Notification.permission === 'denied') {
        return;
      }

      if (Notification.permission === 'granted') {
        updateToken(app, 'granted');
        return;
      }

      if (
        !window?.localStorage?.getItem(IGNORE_NOTIFICATION_SESSION_KEY) &&
        isDesktop
      ) {
        toast(
          <Toast
            title={'Stay up to date!'}
            body={`Turn on notifications and recieve the latest news and updates from ${WHITE_LABEL_MARKET}.`}
            onClick={async () => {
              await updateToken(app);
            }}
          />,
          {
            autoClose: false,
            position: 'bottom-left',
            closeButton: ToastCloseButton,
          },
        );
      }
    }
  },
};
export { firebaseCloudMessaging };
