import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { SWRConfig, State } from 'swr';
import {
  WHITE_LABEL_COMPANY,
  WHITE_LABEL_GUILD_ID,
} from '../../lib/white-label';
import { COLORS } from '../../theme';
import { Box } from '../Flex';
import TidalLogoImage from '../TidalLogoImage';
import TidalFooterDiscordWidget from './FooterDiscordWidget';

// cache provider for discord
function localStorageProvider() {
  // When initializing, we restore the data from `localStorage` into a map.
  if (typeof localStorage !== 'undefined') {
    const map = new Map<string, any>(
      JSON.parse(localStorage.getItem('app-cache') || '[]'),
    );

    // Before unloading the app, we write back all the data into `localStorage`.
    window.addEventListener('beforeunload', () => {
      const appCache = JSON.stringify(Array.from(map.entries()));
      localStorage.setItem('app-cache', appCache);
    });
    return map;
  }

  return new Map<string, any>();
}

const Container = styled.div<{
  isHomePage: boolean;
}>`
  width: 100%;
  padding: 0 ${(props) => (props.isHomePage ? '0rem' : '0')};
  @media only screen and (max-width: 400px) {
    padding: 16px 0;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 400px) {
    display: block;
  }
`;

const LinkTitle = styled.p`
  font: normal normal bold 18px/27px Poppins;
  letter-spacing: 0px;
  color: ${COLORS.GRAY};
  opacity: 1;
  margin-bottom: 1.75rem;
`;

const A = styled.a`
  cursor: pointer;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: ${COLORS.GRAY};
  opacity: 1;
  display: block;
  margin-bottom: 1rem;
  text-decoration: none;
`;

const LinkColumn = styled.div`
  flex-direction: column;
  @media only screen and (max-width: 400px) {
    margin: 0 !important;
  }
`;

const LogoContainer = styled.div`
  margin-top: 5rem;
  margin-right: 2rem;
  text-align: right;
  width: 100%;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Image = styled(TidalLogoImage)`
  width: 3rem;
  vertical-align: bottom;
`;

const Footer = () => {
  const router = useRouter();
  const [isHomePage, setIsHomePage] = useState(true);
  useEffect(() => {
    if (
      window.location.pathname === '/' ||
      window.location.pathname === '/landing'
    ) {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  }, [router.pathname]);
  return (
    <div
      style={{
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'flex-end',
        marginTop: 24,
      }}
    >
      <Container isHomePage={isHomePage}>
        <Grid style={{ width: '100%', padding: 0 }}>
          <Row>
            <Col md={4} xs={12}>
              <LinksContainer>
                <LinkColumn>
                  <LinkTitle>Access</LinkTitle>
                  <A href="/">Home</A>
                  {/* <A href="/">Home</A> */}
                  <A href="/bots">Bots</A>
                  {!process.env.WHITE_LABEL_ORG ? (
                    <A href="/blog">Blog</A>
                  ) : null}
                  <A href="/faq">FAQ</A>
                </LinkColumn>
                <LinkColumn style={{ marginLeft: '4rem' }}>
                  <LinkTitle>Information</LinkTitle>
                  <A href="/info/terms">Terms</A>
                  <A href="/info/privacy">Privacy</A>
                </LinkColumn>
              </LinksContainer>
            </Col>
            <Col
              md={8}
              xs={12}
              style={{
                justifyContent: 'flex-end',
                display: 'flex',
              }}
            >
              {/* <iframe
                src={`https://discord.com/widget?id=${WHITE_LABEL_GUILD_ID}&theme=dark`}
                width="250"
                height="357"
                allowTransparency
                frameBorder="0"
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
              ></iframe> */}
              <TidalFooterDiscordWidget />
            </Col>
          </Row>
        </Grid>
        <div
          style={{
            border: '1px solid #CACFE0',
            width: '100%',
            marginTop: '4rem',
            opacity: 0.5,
          }}
        ></div>
        <p
          style={{
            marginTop: '2rem',
            color: COLORS.GRAY,
            font: 'normal normal normal 16px/25px Poppins',
            letterSpacing: 0,
          }}
        >
          © {new Date().getFullYear()} {WHITE_LABEL_COMPANY}
        </p>
        <Box height={24} />
      </Container>
    </div>
  );
};

export default Footer;
