import React from 'react';
import styled from 'styled-components';

type BoxProps = {
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

const BoxContainer = styled.div`
  width: ${(props: BoxProps) => props.width};
  height: ${(props: BoxProps) => props.height};

  transition: all 2s;
`;

const Box = (props: BoxProps) => {
  const style = { ...(props.style || {}) };

  if (props.height) {
    style.minHeight = props.height;
    style.maxHeight = props.height;
  }
  if (props.width) {
    style.minWidth = props.width;
    style.maxWidth = props.width;
  }

  return <BoxContainer style={style || {}}>{props.children}</BoxContainer>;
};

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const CenterRow = styled(Row)`
  align-items: center;
  justify-content: center;
`;

const CenterCol = styled(Column)`
  align-items: center;
  justify-content: center;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

export { Box, Row, Column, CenterRow, CenterCol, FlexGrow };
