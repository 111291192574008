import { gql } from '@apollo/client';

export const ME = gql`
  query Me {
    me {
      id
      firstName
      lastName
      email
      username
      role
      intercomHash
    }
  }
`;

export const ME_STRIPE_SELLER = gql`
  query Me {
    me {
      id
      isStripeSellerAccountApproved
    }
  }
`;

export const ME_DISCORD_CONNECTION = gql`
  query Me {
    me {
      id
      discordConnection {
        discordUserId
      }
    }
  }
`;

export const ACCOUNT_SETTINGS = gql`
  query AccountDetails {
    me {
      id
      firstName
      lastName
      email
      username
      role
      isStripeSellerAccountApproved
      stripeSellerDashboardSSOLink
      stripeAccountId
      discordConnection {
        discordUserId
        username
      }
      discordConnections {
        discordUserId
        isPrimaryConnection
        username
      }
    }
    affiliatePortalLink {
      affiliateUrl
    }
  }
`;

export const SELLER_PAYMENT_INTENT = gql`
  query SellerPaymentIntent {
    me {
      sellerVerificationPaymentIntent
    }
  }
`;

export const DASHBOARD = gql`
  query Me {
    me {
      id
      username
      firstName
      timeline {
        id
        type
        message
        createdAt
      }
      createdAt
      availableBalance {
        amountCents
        currency
      }
      totalMadeCents
      stripeSellerDashboardSSOLink
      isStripeSellerAccountApproved
      noFeePurchases
      purchasesUntilNoFee
      sellerLevel {
        name
        feePercentage
        salesRequired
      }
      nextSellerLevel {
        name
        feePercentage
        salesRequired
      }
    }
    mySalesCount
    myPurchasesCount
    mySalesPerformance {
      currentMonthTotal
      currentMonthSales
      currentMonthBuyers
      lastMonthTotal
      lastMonthSales
      lastMonthBuyers
    }
  }
`;

export const PROFILE = gql`
  query Me {
    me {
      id
      noFeePurchases
      purchasesUntilNoFee
      sellerLevel {
        name
        feePercentage
        salesRequired
      }
      nextSellerLevel {
        name
        feePercentage
        salesRequired
      }
    }
    mySalesCount
    myPurchasesCount
  }
`;

export const BOTS_FILTERS = gql`
  query BotsFilters {
    allTags
    allOperatingSystems
  }
`;

export const TRENDING_BOTS = gql`
  query TrendingBots($limit: Float!) {
    trendingBots(limit: $limit) {
      botId
      name
      imageUrl
      websiteUrl
      operatingSystem
      tags
      avgPrice
      listings
      sales
      listingMode
    }
  }
`;

export const ALL_BOTS_LIST = gql`
  query Bots(
    $searchQuery: String
    $operatingSystems: [String!]
    $tags: [String!]
  ) {
    bots(
      searchQuery: $searchQuery
      operatingSystems: $operatingSystems
      tags: $tags
    ) {
      id
    }
  }
`;

export const BOTS_LIST = gql`
  query Bots(
    $searchQuery: String
    $operatingSystems: [String!]
    $tags: [String!]
    $sellerUsername: String
  ) {
    bots(
      searchQuery: $searchQuery
      operatingSystems: $operatingSystems
      tags: $tags
      sellerUsername: $sellerUsername
    ) {
      id
      name
      imageUrl
      status
      operatingSystem
      tags
      listingMode
      lastRental {
        priceCents
        rentalDays
        completedAt
      }
      listingsCount(sellerUsername: $sellerUsername)
    }
  }
`;

export const BANS_LIST = gql`
  query allBannedUsers {
    allBannedUsers {
      id
      discordUserId
      email
      notes
      metadata
    }
  }
`;

export const BOT_DETAILS_FOR_EDITING = gql`
  query BotDetailsForEditing($id: String!) {
    bot(id: $id) {
      id
      name
      description
      imageUrl
      interfaceImageUrl
      retailPrice
      websiteUrl
      twitterHandle
      instagramHandle
      operatingSystem
      status
      guideUrl
      advertChannelId
      botmartAdvertChannelId
      macDownloadUrl
      winDownloadUrl
      tags
      authType
      minimumDays
    }
  }
`;

export const BAN_DETAILS_FOR_EDITING = gql`
  query BanDetailsForEditing($id: String!) {
    getBannedUser(id: $id) {
      discordUserId
      email
      notes
      metadata
    }
  }
`;

export const BOT = gql`
  query Bot($id: String!) {
    bot(id: $id) {
      id
      name
      description
      status
      imageUrl
      tags
      retailPrice
      websiteUrl
      twitterHandle
      instagramHandle
      operatingSystem
      interfaceImageUrl
      minimumDays
      listingMode
      orders {
        id
        completedAt
        status
        priceCents
        rentalDays
      }
      lastRental {
        priceCents
        rentalDays
        completedAt
      }
    }
  }
`;

export const CALENDAR_BOT_LISTINGS = gql`
  query ($botId: String!) {
    calendarData(botId: $botId) {
      availability {
        date
        minPriceCents
        maxPriceCents
        avgPriceCents
        availableListings {
          priceCents
          listing {
            id
            endDate
          }
        }
      }
    }
  }
`;

export const BOT_LISTINGS_BARE = gql`
  query BotListings($botId: String!) {
    botListingCounts(botId: $botId) {
      userListingsCount
      staffListingsCount
    }
    myOwnListingIds(botId: $botId)
  }
`;

export const GET_LISTING = gql`
  query Listing($id: Int!) {
    listing(id: $id) {
      id
      email
      password
      key
      secondaryKey
      note
      basePriceCents
      startDate
      endDate
      availability {
        date
        reservedByOrderId
        priceCents
        listingId
      }
      discounts {
        dayCount
        percent
      }
      bot {
        id
        name
        imageUrl
        authType
        listingMode
        lastRental {
          priceCents
          rentalDays
        }
      }
    }
  }
`;

export const CREATE_LISTING_PAGE = gql`
  query Bots {
    bots {
      id
      name
      imageUrl
      authType
      status
      avgPrice
      listingMode
      lastRental {
        priceCents
        rentalDays
        completedAt
      }
    }
  }
`;

export const ORDER = gql`
  query Order($id: Int!) {
    me {
      id
      stripeSellerDashboardSSOLink
    }
    order(id: $id) {
      id
      discordSupportChannelGuildId
      discordSupportChannelId
      lastResetRequestAt
      completedAt
      startDate
      endDate
      status
      rentalDays
      priceCents
      buyer {
        id
      }
      seller {
        id
      }
      listing {
        id
        bot {
          id
          name
          authType
          imageUrl
          tags
          operatingSystem
          guideUrl
          macDownloadUrl
          winDownloadUrl
        }
        key
        secondaryKey
        email
        password
      }
    }
  }
`;

export const PURCHASES = gql`
  query Purchases($flag: String) {
    myPurchases(flag: $flag) {
      id
      completedAt
      reference
      status
      startDate
      endDate
      priceCents
      rentalDays
      discordSupportChannelGuildId
      discordSupportChannelId
      lastResetRequestAt
      createdAt
      listing {
        id
        bot {
          id
          imageUrl
          name
          tags
          operatingSystem
          guideUrl
          macDownloadUrl
          winDownloadUrl
          listingMode
          authType
        }
        key
        secondaryKey
        email
        password
      }
    }
  }
`;

export const USER_LISTINGS_BY_BOT = gql`
  query Listings {
    myListingsByBots {
      id
      name
      tags
      imageUrl
      avgPrice
      sales {
        id
      }
      activeRentals {
        id
      }
      futureRentals {
        id
      }
      activeListings {
        id
        startDate
        endDate
        basePriceCents
        key
        secondaryKey
        email
        password
        avgPrice
        isArchived
      }
      expiredListings {
        id
        endDate
        startDate
        key
        secondaryKey
        email
        password
        basePriceCents
      }
    }
  }
`;

export const SALES = gql`
  query Sales($flag: String) {
    mySales(flag: $flag) {
      id
      completedAt
      reference
      status
      payoutAt
      priceCents
      rentalDays
      payoutAmountCents
      startDate
      endDate
      createdAt
      discordSupportChannelGuildId
      discordSupportChannelId
      lastResetRequestAt
      listing {
        key
        secondaryKey
        email
        password
        id
        bot {
          id
          imageUrl
          guideUrl
          name
          tags
          operatingSystem
          guideUrl
          macDownloadUrl
          winDownloadUrl
          listingMode
          authType
        }
      }
    }
  }
`;

export const ALL_ORDERS = gql`
  query AllOrders($searchQuery: String) {
    allOrders(searchQuery: $searchQuery) {
      id
      completedAt
      payoutAt
      endDate
      closedAt
      refundedAt
      status
      buyerDiscordId
      sellerDiscordId
      priceCents
      buyerSubtotalWithFee
      rentalDays
      isNoFeePurchase
      listing {
        id
        bot {
          id
          name
          imageUrl
        }
      }
      reservedAvailability {
        date
        priceCents
      }
    }
  }
`;

export const CONFIRM_ORDER = gql`
  query ConfirmOrder($id: Int!) {
    listing(id: $id) {
      whiteLabelOrg
      bot {
        name
        imageUrl
        tags
      }
    }
    me {
      purchasesUntilNoFee
      creditBalance
    }
  }
`;

export const RECENT_ORDER = gql`
  query RecentOrder {
    recentOrder {
      id
      status
      listing {
        id
        bot {
          id
          name
        }
      }
    }
  }
`;

export const USERS = gql`
  query Users($offset: Int!, $searchQuery: String!) {
    users(offset: $offset, searchQuery: $searchQuery) {
      id
      firstName
      lastName
      username
      email
      role
      whiteLabelOrg
      connections {
        discordUserId
      }
    }
  }
`;

export const AFFILIATE_WEBHOOKS = gql`
  query AffiliateWebhooks {
    myWebhooks {
      id
      webhookUrl
      webhookId
      webhookSecret
    }
  }
`;
