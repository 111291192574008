import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { WHITE_LABEL_ACCENT_COLOR } from '../../../lib/white-label';

const TidalButtonWrapper = styled.button`
  height: 42px;
  padding: 0 12px;
  border-radius: 10px;
  width: 100%;

  font-weight: 600;
  font-size: 14px;
  div {
    justify-content: center;
    display: flex;
    align-items: center;
    grid-gap: 0 8px;
  }

  opacity: ${(props: any) => (props.disabled ? '.5' : '1')};
  cursor: ${(props: any) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const TidalSuccessTagWrapper = styled(TidalButtonWrapper)<{
  tint: boolean;
}>`
  background-color: ${(props) =>
    props.tint
      ? `${props.theme.colors.tags[0]}3d`
      : props.theme.colors.tags[0]};
  color: ${(props) => (props.tint ? props.theme.colors.tags[0] : '#fff')};
`;

const TidalPrimaryTagWrapper = styled(TidalButtonWrapper)<{
  tint: boolean;
}>`
  background-color: ${(props) =>
    props.tint
      ? `${props.theme.colors.tags[1]}3d`
      : props.theme.colors.tags[1]};
  color: ${(props) => (props.tint ? props.theme.colors.tags[1] : '#fff')};
`;

const TidalWarningTagWrapper = styled(TidalButtonWrapper)<{
  tint: boolean;
}>`
  background-color: ${(props) =>
    props.tint
      ? `${props.theme.colors.tags[2]}3d`
      : props.theme.colors.tags[2]};
  color: ${(props) => (props.tint ? props.theme.colors.tags[2] : '#fff')};
`;

const TidalInfoTagWrapper = styled(TidalButtonWrapper)<{
  tint: boolean;
}>`
  background-color: ${(props) =>
    props.tint ? `${WHITE_LABEL_ACCENT_COLOR}3d` : WHITE_LABEL_ACCENT_COLOR};
  color: ${(props) => (props.tint ? props.theme.colors.tags[3] : '#fff')};
`;

const TidalErrorTagWrapper = styled(TidalButtonWrapper)<{
  tint: boolean;
}>`
  background-color: ${(props) =>
    props.tint
      ? `${props.theme.colors.tags[4]}3d`
      : props.theme.colors.tags[4]};
  color: ${(props) => (props.tint ? props.theme.colors.tags[4] : '#fff')};
`;

const TidalInvertTagWrapper = styled(TidalButtonWrapper)<{
  tint: boolean;
}>`
  background-color: ${(props) => (props.tint ? `#0000003d` : '#000000')};
  color: ${(props) => (props.tint ? props.theme.colors.tags[4] : '#fff')};
`;

const TidalDefault = styled(TidalButtonWrapper)<{
  tint: boolean;
}>`
  background-color: ${(props) =>
    props.tint
      ? `${props.theme.colors.button[2]}`
      : props.theme.colors.button[2]};
  color: ${(props) => (props.tint ? props.theme.colors.text[0] : '#000')};
`;

interface ITidalButton {
  type: string;
  text: string;
  tint: boolean;
  Icon?: any;
  isLoading?: boolean;
  hasIcon?: boolean;
  disabled?: boolean;
  onClick?: (e?: any) => void;
  className?: string;
}

const TidalButton = ({
  type,
  text,
  tint,
  Icon,
  hasIcon,
  isLoading,
  disabled,
  onClick,
  className,
}: ITidalButton): JSX.Element => {
  if (type == 'success')
    return (
      <TidalSuccessTagWrapper
        onClick={!isLoading ? onClick : undefined}
        tint={tint}
        disabled={disabled}
        className={className}
      >
        {isLoading ? (
          <TailSpin color="#fff" height={30} width={30} />
        ) : (
          <div>
            {hasIcon ? Icon : null}
            <p>{text}</p>
          </div>
        )}
      </TidalSuccessTagWrapper>
    );
  if (type == 'primary')
    return (
      <TidalPrimaryTagWrapper
        onClick={!isLoading ? onClick : undefined}
        tint={tint}
        disabled={disabled}
        className={className}
      >
        {isLoading ? (
          <TailSpin color="#fff" height={30} width={30} />
        ) : (
          <div>
            {hasIcon ? Icon : null}
            <p>{text}</p>
          </div>
        )}
      </TidalPrimaryTagWrapper>
    );
  if (type == 'warning')
    return (
      <TidalWarningTagWrapper
        onClick={!isLoading ? onClick : undefined}
        tint={tint}
        disabled={disabled}
        className={className}
      >
        {isLoading ? (
          <TailSpin color="#fff" height={30} width={30} />
        ) : (
          <div>
            {hasIcon ? Icon : null}
            <p>{text}</p>
          </div>
        )}
      </TidalWarningTagWrapper>
    );
  if (type == 'invert')
    return (
      <TidalInvertTagWrapper
        onClick={!isLoading ? onClick : undefined}
        tint={tint}
        disabled={disabled}
        className={className}
      >
        {isLoading ? (
          <TailSpin color="#fff" height={30} width={30} />
        ) : (
          <div>
            {hasIcon ? Icon : null}
            <p>{text}</p>
          </div>
        )}
      </TidalInvertTagWrapper>
    );
  if (type == 'info')
    return (
      <TidalInfoTagWrapper
        onClick={!isLoading ? onClick : undefined}
        tint={tint}
        disabled={disabled}
        className={className}
      >
        {isLoading ? (
          <TailSpin color="#fff" height={30} width={30} />
        ) : (
          <div>
            {hasIcon ? Icon : null}
            <p>{text}</p>
          </div>
        )}
      </TidalInfoTagWrapper>
    );
  if (type == 'error')
    return (
      <TidalErrorTagWrapper
        onClick={!isLoading ? onClick : undefined}
        tint={tint}
        disabled={disabled}
        className={className}
      >
        {isLoading ? (
          <TailSpin color="#fff" height={30} width={30} />
        ) : (
          <div>
            {hasIcon ? Icon : null}
            <p>{text}</p>
          </div>
        )}
      </TidalErrorTagWrapper>
    );
  if (type == 'default')
    return (
      <TidalDefault
        onClick={!isLoading ? onClick : undefined}
        tint={tint}
        disabled={disabled}
        className={className}
      >
        {isLoading ? (
          <TailSpin color="#fff" height={30} width={30} />
        ) : (
          <div>
            {hasIcon ? Icon : null}
            <p>{text}</p>
          </div>
        )}
      </TidalDefault>
    );
};

TidalButton.defaultProps = {
  hasIcon: false,
  disabled: false,
};

export default TidalButton;
