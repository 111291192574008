import { store } from "@risingstack/react-easy-state";

const mobileMenu = store({
  toggled: false,
  setToggled: (open: boolean) => {
    mobileMenu.toggled = open;
  },
});

export default mobileMenu;
