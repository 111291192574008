import { ThemeWeb } from "styled-components";

const lightThemeWeb: ThemeWeb = {
  borderRadius: "12px",

  colors: {
    background: "#FFFFFF",
    primary: "#F9FAFF",
    secondary: "#F2F4F9",
    accent: "#1CBAF0",
    shadowColor: "rgba(0, 27, 31, 0.08)",
    borderColors: ["#E5E8F0", "#DFE3EE"],
    text: ["#9DA3B7", "#000000", "#05030D", "#F35C8A", "#23E588"],
    button: ["#1CBAF0", "#F35C8A", "#F2F4F9"],
    tags: ["#23E588", "#6046FF", "#F3C05C", "#1CBAF0", "#F35C8A", "#E3E7F2"]
  },
};

const darkThemeWeb: ThemeWeb = {
  borderRadius: "12px",

  colors: {
    background: "#FFFFFF",
    primary: "#EBEFF5",
    secondary: "#333B46",
    accent: "#52C097",
    shadowColor: "#001B1F",
    borderColors: ["#E5E8F0", "#DFE3EE"],
    text: [""],
    button: ["#1CBAF0", "#F35C8A", "#F2F4F9"],
    tags: ["#23E5883d", "#6046FF3d", "#F3C05C3d", "#1CBAF03d", "#F35C8A3d"]
  },
};

export { lightThemeWeb, darkThemeWeb };