import { loadStripe } from '@stripe/stripe-js';

export const tidalStripePromise = loadStripe(process.env.TIDAL_STRIPE_TOKEN);
export const tidalShopStripePromise = loadStripe(
  process.env.TIDAL_SHOP_STRIPE_TOKEN,
);

export const botmartStripePromise = loadStripe(
  process.env.BOTMART_STRIPE_TOKEN,
);

export const copsupplyStripePromise = loadStripe(
  process.env.COPSUPPLY_STRIPE_TOKEN,
);
