import { gql } from '@apollo/client';

export const REGISTER = gql`
  mutation Register($registerData: RegisterInput!) {
    register(registerData: $registerData) {
      id
      accessToken
      refreshToken
    }
  }
`;

export const LOGIN = gql`
  mutation Login($usernameOrEmail: String!, $password: String!) {
    login(usernameOrEmail: $usernameOrEmail, password: $password) {
      id
      accessToken
      refreshToken
      discordConnection {
        discordUserId
      }
      hasVerifiedEmail
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout($refreshToken: String!) {
    logout(refreshToken: $refreshToken)
  }
`;

export const VERIFY_GOOGLE = gql`
  mutation VerifyGoogle($googleData: GoogleInput!) {
    verifyGoogle(googleData: $googleData) {
      id
      accessToken
      refreshToken
      discordConnection {
        discordUserId
      }
      hasVerifiedEmail
    }
  }
`;

export const VERIFY_GOOGLE_ID = gql`
  mutation VerifyGoogleId($googleData: GoogleIdInput!) {
    verifyGoogleId(googleData: $googleData) {
      id
      accessToken
      refreshToken
      discordConnection {
        discordUserId
      }
      hasVerifiedEmail
    }
  }
`;

export const SAVE_NOTIF_TOKEN = gql`
  mutation saveNotificationToken($token: String!, $source: String) {
    saveNotificationToken(token: $token, source: $source) {
      id
    }
  }
`;

export const CREATE_LISTING = gql`
  mutation CreateListing($createListingData: CreateListingInput!) {
    createListing(createListingData: $createListingData) {
      id
    }
  }
`;

export const ARCHIVE_LISTING = gql`
  mutation ArchiveListing($id: Int!) {
    archiveListing(id: $id)
  }
`;

export const UPDATE_LISTING = gql`
  mutation UpdateListing($id: Int!, $updateListingData: UpdateListingInput!) {
    updateListing(id: $id, updateListingData: $updateListingData)
  }
`;

export const ONBOARD_STRIPE_SELLER = gql`
  mutation OnboardStripeSeller($countryCode: String) {
    onboardStripeSeller(countryCode: $countryCode)
  }
`;

export const CREATE_WEBHOOK = gql`
  mutation CreateWebhook($url: String!) {
    createWebhook(url: $url) {
      id
      webhookUrl
    }
  }
`;

export const CREATE_MANUAL_PAYOUT = gql`
  mutation CreateManualPayout($amount: Int!, $currency: String!) {
    createManualPayout(amount: $amount, currency: $currency)
  }
`;

export const PAYOUT_ORDER = gql`
  mutation PayoutOrder($orderId: Int!) {
    payoutOrder(orderId: $orderId)
  }
`;

export const UPDATE_WEBHOOK = gql`
  mutation UpdateWebhook($id: String!, $url: String!) {
    updateWebhook(id: $id, url: $url) {
      id
      webhookUrl
    }
  }
`;

export const REMOVE_WEBHOOK = gql`
  mutation RemoveWebhook($id: String!) {
    removeWebhook(id: $id)
  }
`;

export const SIGN_UP_FOR_AFFILIATE = gql`
  mutation SignUpForAffiliate {
    signUpForAffiliate {
      affiliateId
    }
  }
`;

export const CHECK_STRIPE_SELLER_APPROVAL = gql`
  mutation CheckStripeSellerApproval {
    checkStripeSellerApproval
  }
`;

export const START_ORDER = gql`
  mutation StartOrder($startOrderData: StartOrderInput!) {
    startOrder(startOrderData: $startOrderData)
  }
`;

export const CREATE_DISCORD_CONNECTION = gql`
  mutation createDiscordConnection($code: String!) {
    createDiscordConnection(code: $code) {
      discordUserId
    }
  }
`;

export const DELETE_DISCORD_CONNECTION = gql`
  mutation deleteDiscordConnection($discordUserId: String!) {
    deleteDiscordConnection(discordUserId: $discordUserId)
  }
`;

export const SET_PRIMARY_DISCORD_CONNECTION = gql`
  mutation setPrimaryConnection($discordUserId: String!) {
    setPrimaryConnection(discordUserId: $discordUserId)
  }
`;

export const CREATE_BOT = gql`
  mutation createBot($createBotData: CreateBotInput!) {
    createBot(createBotData: $createBotData) {
      id
    }
  }
`;

export const UPDATE_BOT = gql`
  mutation updateBot($id: String!, $updateBotData: UpdateBotInput!) {
    updateBot(id: $id, updateBotData: $updateBotData) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: String!, $updateUserData: UpdateUserInput!) {
    updateUser(id: $id, updateUserData: $updateUserData) {
      id
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($usernameOrEmail: String!) {
    forgotPassword(usernameOrEmail: $usernameOrEmail)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($resetPasswordData: ResetPasswordInput!) {
    resetPassword(resetPasswordData: $resetPasswordData)
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($userId: String!, $token: String!) {
    verifyEmail(userId: $userId, token: $token)
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation resendVerificationEmail {
    resendVerificationEmail
  }
`;

export const REQUEST_BOT_RESET = gql`
  mutation requestBotReset($orderId: Int!) {
    requestBotReset(orderId: $orderId)
  }
`;

export const NOTIFIY_PAID_ORDER_FAILED = gql`
  mutation notifyPaidOrderFailed {
    notifyPaidOrderFailed
  }
`;

export const RETRY_ORDER = gql`
  mutation retryOrder($id: Int!) {
    retryOrder(id: $id)
  }
`;

export const REFUND_ORDER = gql`
  mutation refundOrder(
    $id: Int!
    $refundReason: String!
    $punish: Boolean
    $partialAmount: Int
    $acceptCredit: Boolean
  ) {
    refundOrder(
      id: $id
      refundReason: $refundReason
      punish: $punish
      partialAmount: $partialAmount
      acceptCredit: $acceptCredit
    )
  }
`;

export const REMOVE_STRIPE_SELLER_ACCOUNT = gql`
  mutation removeStripeSellerAccount {
    removeStripeSellerAccount
  }
`;

export const CREATE_BAN = gql`
  mutation createBan($createBanData: CreateBanInput!) {
    createBan(createBanData: $createBanData) {
      success
    }
  }
`;

export const UPDATE_BAN = gql`
  mutation updateBan($id: String!, $updateBanData: UpdateBanInput!) {
    updateBan(id: $id, updateBanData: $updateBanData) {
      id
    }
  }
`;

export const DELETE_BAN = gql`
  mutation deleteBan($id: String!) {
    deleteBan(id: $id) {
      success
    }
  }
`;

export const GET_RENTAL_PRICING = gql`
  mutation getRentalPricing(
    $priceData: ListingPriceInput!
    $sellerUsername: String
  ) {
    getRentalPricing(priceData: $priceData, sellerUsername: $sellerUsername) {
      id
      avgPricePerDay
      totalPrice
      discount {
        dayCount
        percent
      }
      potentialDiscount {
        dayCount
        percent
      }
    }
  }
`;

export const GET_RENTAL_MAX_DATE = gql`
  mutation getRentalListingMaxDate($startData: ListingMaxDateInput!) {
    getRentalListingMaxDate(startData: $startData) {
      maxDate
    }
  }
`;

export const EXTEND_ORDER = gql`
  mutation ExtendOrder($options: ExtendOrderInput!) {
    extendOrder(options: $options)
  }
`;

export const EXPEDITE_ORDER = gql`
  mutation ExpediteOrder($orderId: Int!) {
    expediteOrder(orderId: $orderId)
  }
`;
