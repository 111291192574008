export const LOCAL_STORAGE = {
  DISCORD_OAUTH_STATE: 'discordOAuthState',
  DISCORD_AUTH_STATUS: 'discordAuthStatus',
  REDIRECT_AFTER_AUTH: 'redirectAfterAuth',
  LISTING_BOT_ID: 'listingBotId',
  HAS_TOURED_NEW_LISTING: 'hasTouredNewListing',
  HAS_TOURED_NEW_LISTING_CONFIRM: 'hasTouredNewListingConfirm',
  HAS_TOURED_TRANSACTIONS_LISTING: 'hasTouredTransactionsListings',
  HAS_TOURED_TRANSACTIONS_PURCHASE: 'hasTouredTransactionsPurchase',
  HAS_TOURED_TRANSACTIONS_SALES: 'hasTouredTransactionsSales',
  HAS_TOURED_LISTING_PURCHASE: 'hasTouredListingPurchase',
  HAS_TOURED_LISTING_PURCHASE_CONFIRM: 'hasTouredListingPurchaseConfirm',
  HAS_TOURED_SHOP_PAGE: 'hasTouredShopPage',
  HAS_TOURED_SHOP_FILTER: 'hasTouredShopPageFilter',
  HAS_TOURED_SHOP_ITEM: 'hasTouredShopItem',
};

export const COOKIE = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
};
