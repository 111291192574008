import * as React from "react"
import { SVGProps } from "react"

const EbayViews = (props: {color : string}) => (
  <svg
    width={21}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 10.5a4.375 4.375 0 0 1 4.375-4.375H8.75a.875.875 0 1 1 0 1.75H6.125a2.625 2.625 0 1 0 0 5.25H8.75a.875.875 0 1 1 0 1.75H6.125A4.375 4.375 0 0 1 1.75 10.5ZM11.375 7c0-.483.392-.875.875-.875h2.625a4.375 4.375 0 0 1 0 8.75H12.25a.875.875 0 1 1 0-1.75h2.625a2.625 2.625 0 1 0 0-5.25H12.25A.875.875 0 0 1 11.375 7Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 10.5c0-.483.392-.875.875-.875h5.25a.875.875 0 1 1 0 1.75h-5.25A.875.875 0 0 1 7 10.5Z"
      fill={props.color}
    />
  </svg>
)

export default EbayViews
