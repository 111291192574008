import * as React from "react"
import { SVGProps } from "react"

const Servers = (props: {color : string}) => (
  <svg
    width={21}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.75 13.125H5.25a.875.875 0 0 0-.875.875v2.625c0 .483.392.875.875.875h10.5a.875.875 0 0 0 .875-.875V14a.875.875 0 0 0-.875-.875Zm-10.5-1.75A2.625 2.625 0 0 0 2.625 14v2.625A2.625 2.625 0 0 0 5.25 19.25h10.5a2.625 2.625 0 0 0 2.625-2.625V14a2.625 2.625 0 0 0-2.625-2.625H5.25ZM15.75 3.5H5.25a.875.875 0 0 0-.875.875V7c0 .483.392.875.875.875h10.5A.875.875 0 0 0 16.625 7V4.375a.875.875 0 0 0-.875-.875ZM5.25 1.75a2.625 2.625 0 0 0-2.625 2.625V7A2.625 2.625 0 0 0 5.25 9.625h10.5A2.625 2.625 0 0 0 18.375 7V4.375A2.625 2.625 0 0 0 15.75 1.75H5.25Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.688 15.313c0-.484.391-.876.875-.876h4.812a.875.875 0 1 1 0 1.75H6.562a.875.875 0 0 1-.875-.875ZM5.688 5.688c0-.484.391-.875.875-.875h4.812a.875.875 0 1 1 0 1.75H6.562a.875.875 0 0 1-.875-.875Z"
      fill={props.color}
    />
    <path
      d="M15.313 15.313a.875.875 0 1 1-1.75 0 .875.875 0 0 1 1.75 0ZM15.313 5.688a.875.875 0 1 1-1.75 0 .875.875 0 0 1 1.75 0Z"
      fill={props.color}
    />
  </svg>
)

export default Servers
