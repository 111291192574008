import {
  ApolloClient,
  createHttpLink,
  from,
  fromPromise,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { COOKIE, LOCAL_STORAGE } from '../constants';

const cookies = new Cookies();

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient;

export function createApolloClient() {
  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const refreshToken = () => {
    return new Promise((resolve, reject) => {
      const token = cookies.get(COOKIE.REFRESH_TOKEN);
      if (!token) reject('No refresh token');
      fetch(`${process.env.BACKEND_URI}/auth/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ token }),
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then(resolve)
        .catch(reject);
    });
  };

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      let errorMessage = 'An error has occurred';
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          switch (err.message) {
            case 'MUST_VERIFY_EMAIL': {
              localStorage.setItem(
                LOCAL_STORAGE.REDIRECT_AFTER_AUTH,
                window.location.href,
              );
              window.location.assign('/register/verify-email');
              return;
            }
            case 'MUST_AUTHENTICATE_DISCORD': {
              localStorage.setItem(
                LOCAL_STORAGE.REDIRECT_AFTER_AUTH,
                window.location.href,
              );
              window.location.assign('/register/connect-discord');
              return;
            }
            case 'MUST_BE_AUTHENTICATED':
              return;
            case 'INVALID_REFRESH_TOKEN': {
              cookies.remove(COOKIE.ACCESS_TOKEN, { path: '/' });
              cookies.remove(COOKIE.REFRESH_TOKEN, { path: '/' });
              return;
            }
            case 'EXPIRED_ACCESS_TOKEN':
              return fromPromise(
                refreshToken().catch((error) => {
                  // Handle token refresh errors e.g clear stored tokens, redirect to login
                  cookies.remove(COOKIE.ACCESS_TOKEN, { path: '/' });
                  cookies.remove(COOKIE.REFRESH_TOKEN, { path: '/' });
                  window.location.assign('/login');
                }),
              )
                .filter((value) => {
                  return Boolean(value);
                })
                .flatMap(({ token }) => {
                  const oldHeaders = operation.getContext().headers;

                  cookies.set(COOKIE.ACCESS_TOKEN, token, { path: '/' });

                  const myCookies = cookies.getAll();

                  // modify the operation context with a new token
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      Authorization: `Bearer ${myCookies[COOKIE.ACCESS_TOKEN]}`,
                    },
                  });

                  // retry the request, returning the new observable
                  return forward(operation);
                });
            default:
              if (
                err.extensions &&
                err.extensions.exception &&
                err.extensions.exception.response &&
                err.extensions.exception.response.message
              ) {
                errorMessage = String(
                  err.extensions.exception.response.message,
                );
              } else if (
                err.extensions &&
                err.extensions.exception &&
                err.extensions.exception.response &&
                err.extensions.exception.response.code
              ) {
                errorMessage = String(err.extensions.exception.response.code);
              } else {
                errorMessage = String(err.message);
              }
          }
        }
      }
      toast(errorMessage, { type: 'error' });
    },
  );

  const authLink = setContext((_, { headers }) => {
    const myCookies = cookies.getAll();
    const authHeaders: { Authorization?: string } = {};
    if (myCookies[COOKIE.ACCESS_TOKEN]) {
      authHeaders.Authorization = `Bearer ${myCookies[COOKIE.ACCESS_TOKEN]}`;
    }
    return {
      headers: {
        ...headers,
        ...authHeaders,
      },
    };
  });

  const httpLink = createHttpLink({
    uri: `${process.env.BACKEND_URI}/graphql`,
  });

  const client = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return client;
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
